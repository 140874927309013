import { useState, useEffect, createContext, useContext } from "react";

const sidebarContext = createContext();
sidebarContext.displayName = "sidebarContext";

export const useSidebarContext = () => useContext(sidebarContext);

const SidebarProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleSideBar = () => {
    setSidebarOpen((prevState) => !prevState);
  };
  const checkResolutionForToggle = () => {
    if (window.innerWidth <= 1024) {
      setSidebarOpen(false);
    }
  };

  /* Rezing window */
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1024) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    }
    /* For first time mounting sidebar */
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <sidebarContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        toggleSideBar,
        checkResolutionForToggle,
      }}
    >
      {children}
    </sidebarContext.Provider>
  );
};

export default SidebarProvider;
