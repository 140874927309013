import { useSidebarContext } from "../../../context/sidebarContext";
import DropdownManu from "../common/DropdownManu";
import useStateToggler from "./../../../hooks/useStateToggler";
import useOutsideClick from "./../../../hooks/useOutsideClick";

const TopBar = () => {
  const { sidebarOpen, toggleSideBar } = useSidebarContext();
  const [dropdownOpen, setDropdownOpen, toggleDropdownOpen] = useStateToggler(
    false
  );
  const dropdownMenoRef = useOutsideClick(() => {
    setDropdownOpen(false);
  });

  return (
    <div className="top-bar d-flex">
      {/* Left */}
      <div className="d-flex">
        <button onClick={toggleSideBar} className="btn btn-clear">
          <i className={`fas fa-chevron-${sidebarOpen ? "left" : "right"}`}></i>
        </button>
      </div>

      {/* Middle */}
      <div className="d-flex flex-fill"></div>

      {/* Right */}
      <div
        className="d-flex pointer"
        onClick={toggleDropdownOpen}
        ref={dropdownMenoRef}
      >
        <div>Your Company Name</div>
        <button className="btn btn-clear">
          <i className={`fas fa-chevron-${dropdownOpen ? "up" : "down"}`}></i>
        </button>
        {dropdownOpen && <DropdownManu setDropdownOpen={setDropdownOpen} />}
      </div>
    </div>
  );
};

export default TopBar;
