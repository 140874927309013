import SidebarProvider from "../../context/sidebarContext";
import MainLayout from "./MainLayout";
import "../../styles/style-main.css";

const Main = () => {
  return (
    <SidebarProvider>
      <MainLayout />
    </SidebarProvider>
  );
};

export default Main;
