import { NavLink } from "react-router-dom";

export default function SignIn() {
  return (
    <div className="d-flex flex-column justify-content-center">
      <div>Please do sign in</div>
      <NavLink to="/sign-up">Sign up</NavLink>
    </div>
  );
}
