import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import ModalAlert from "./../../../../components/modal/ModalAlert";

const OrderNotice = () => {
  const [orderNotice, setOrderNotice] = useState(false);

  return (
    <>
      <button onClick={() => setOrderNotice(true)}>Show</button>
      <AnimatePresence>
        {orderNotice && (
          <ModalAlert
            closeOnClickOutside={true}
            setModalVisibility={setOrderNotice}
            title="Order Notice"
            description="This app is under construction!"
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default OrderNotice;
