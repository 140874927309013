import React from "react";
import { Outlet } from "react-router-dom";
import { useSidebarContext } from "../../context/sidebarContext";
import SideBar from "./common/SideBar";
import TopBar from "./common/TopBar";

export default function MainLayout() {
  const { sidebarOpen } = useSidebarContext();
  return (
    <div className={`main-wrapper ${sidebarOpen && "open"}`}>
      <SideBar />
      <div className="main-content-wrapper">
        <TopBar />
        <Outlet />
      </div>
    </div>
  );
}
