import OrderNotice from "./OrderNotice";

const Order = () => {
  return (
    <div>
      <h1>Order Page</h1>
      <p>
        Show notice <OrderNotice />
      </p>
    </div>
  );
};

export default Order;
