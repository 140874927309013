import { Routes, Route, Navigate } from "react-router-dom";
import Auth from "./pages/auth/Auth";
import SignIn from "./pages/auth/partials/sign-in/SignIn";
import SignUp from "./pages/auth/partials/sign-up/SignUp";

import Main from "./pages/main/Main";
import Settings from "./pages/main/partials/settings/Settings";
import Order from "./pages/main/partials/order/Order";
import CompanyProfile from "./pages/main/partials/company-profile/CompanyProfile";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Routes>
      <Route element={<Auth />}>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
      </Route>
      <Route path="/" element={<Main />}>
        <Route path="/order" element={<Order />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
      </Route>
      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not-found" replace />} />
    </Routes>
  );
}

export default App;
