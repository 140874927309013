import { NavLink } from "react-router-dom";
import logo from "../../../resources/logo.png";
import { useSidebarContext } from "./../../../context/sidebarContext";

const SideBar = () => {
  const { checkResolutionForToggle } = useSidebarContext();
  const currentYear = new Date().getFullYear();
  const toogleFullscreen = () => {
    window.toogleFullscreen();
  };

  return (
    <div className="sidebar-wrapper sidebar d-flex flex-column">
      {/* Sidebar Header - Logo */}
      <div className="header">
        <div onDoubleClick={toogleFullscreen}>
          <img src={logo} alt="Company Logo" height="40" />
        </div>
      </div>

      {/* Sidebar Content - All Navigation */}
      <div className="content flex-fill">
        <NavLink
          to="order"
          className="sb-item-alone"
          onClick={checkResolutionForToggle}
        >
          Order
        </NavLink>
        <NavLink
          to="settings"
          className="sb-item-alone"
          onClick={checkResolutionForToggle}
        >
          Settings
        </NavLink>
      </div>

      {/* Sidebar Footer - Company Name */}
      <div className="footer uppercase ng-scope">
        <span className="copyright-text ng-binding">
          COPYRIGHT {currentYear}
          <br />
          ALL RIGHT RESERVED BY LANDKNOCK LTD.
        </span>
      </div>
    </div>
  );
};

export default SideBar;
