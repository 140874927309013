import { NavLink } from "react-router-dom";

const DropdownManu = ({ setDropdownOpen }) => {
  const logout = () => {
    console.log("Logged out!");
  };

  return (
    <div
      className="dropdown-manu"
      onClick={() => {
        setDropdownOpen(false);
      }}
    >
      <NavLink className="dd-item pointer" to="company-profile">
        <div className="title">Your Company Name</div>
        <div className="text">Company Profile</div>
      </NavLink>
      <NavLink className="dd-item pointer" to="my-profile">
        <div className="text">My Profile</div>
      </NavLink>
      <NavLink className="dd-item pointer" to="/settings">
        <div className="text">Settings</div>
      </NavLink>
      <div className="dd-item pointer" onClick={logout}>
        <div className="text">
          <i className="fas fa-sign-out-alt"></i> Sign out
        </div>
      </div>
    </div>
  );
};

export default DropdownManu;
