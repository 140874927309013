import { NavLink } from "react-router-dom";

export default function SignUp() {
  return (
    <div>
      <div>Sign Up Here</div>
      <NavLink to="/sign-in">Sign In</NavLink>
    </div>
  );
}
