import { useState } from "react";

/**
 * Use this to have addional toggle function for boolen state.
 * @param {initialValue} [Optional] Takes boolen value.
 * @return state, setState and toggleState.
 */
const useStateToggler = (initialValue) => {
  const [state, setState] = useState(() => {
    return initialValue || false;
  });

  const toggleState = () => {
    if (state) setState(false);
    else setState(true);
  };

  return [state, setState, toggleState];
};

export default useStateToggler;
